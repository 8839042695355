import { useRouter } from "next/router";
import { useEffect } from "react";
import { urls } from "src/constants";
import { useAuth } from "src/hooks/useAuth";
import { useNotifications } from "src/hooks/useNotifications";

export default function IndexPage() {
  const auth = useAuth();
  const router = useRouter();
  const notifications = useNotifications();

  useEffect(() => {
    if (auth.isAuthenticated) {
      const isHasNoForm = auth.isAuthenticated && !auth.user?.formPk;
      if (isHasNoForm) {
        notifications.add("info", "Please complete your profile");
        router.push("/account/profile");
      } else {
        router.push("/form/editor");
      }
    } else {
      router.push(urls.account.login);
    }
  }, []);

  return <></>;
}
